import { service } from "@/utils/request";
import { AxiosResponse } from "axios";
import { api } from "../baseUrl";


// tree list
export const getTreeList = (data?:any): Promise<AxiosResponse<object>> =>{
    return service({
        method:'get',
        url:`${api}/agent-api/api/growth_folder_list`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
// 文章list
export const getArticleList = (data:any): Promise<AxiosResponse<object>> =>{
    return service({
        method:'get',
        url:`${api}/agent-api/api/growth_article_list?category_id=${data.id}`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
// 搜索
export const articleSearch = (data:any): Promise<AxiosResponse<object>> =>{
    return service({
        method:'get',
        url:`${api}/agent-api/api/growth_article_list?title=${data.title}`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}

//文章详情
export const getArticleDetails = (data:any): Promise<AxiosResponse<object>> =>{
    return service({
        method:'post',
        url:`${api}/agent-api/api/growth_article_detail`,
        data
    }).then(
        (res: AxiosResponse): AxiosResponse<object> => {
            return res;
        }
    );
}
