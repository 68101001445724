
import {defineComponent,ref,reactive,toRefs, onMounted, onBeforeMount,} from "vue";
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue';
import type { TreeProps } from 'ant-design-vue';
import { DownOutlined,StepBackwardOutlined,FolderOutlined} from '@ant-design/icons-vue';
import { getTreeList,getArticleList,articleSearch } from '@/api/grow'
import { useRouter } from "vue-router";
export default defineComponent({
    name:"grow",
    components:{
        breadcrumb,
        DownOutlined,
        StepBackwardOutlined,
        FolderOutlined
    },
    setup(){
        const breadData = ref([
            {title:'首页',path:'#/'},
            {title:'成长',path:'/index/grow'},
        ])
        const router = useRouter();
        const expandedKeys = ref<string[]>(['0-0']);
        const selectedKeys = ref<string[]>([]);
        const treeData = ref([
        ]);
        const query = ref('')
        const article = ref([])

        onBeforeMount(()=>{
            getTree()
        })

        const getTree = ()=>{
            getTreeList().then((res:any)=>{
                if(res.code == 200){
                    (treeData.value as any) = res.data.models;
                    getArticle((treeData.value as Array<any>)[0].id)
                }                
            })
        }

        const getArticle = (id:string)=>{
            getArticleList({id}).then((res:any)=>{
                if(res.code == 200){
                    article.value = res.data.models;                    
                }
            })
        }

        const treeSelect = (selectedKeys:any,e:any)=>{
            let id = e.node.id;
            getArticle(id)
        }

        const articleClick = (val:any) =>{
            router.push({name:'details',query:{id:val.id}})
        }

        const search = ()=>{
            articleSearch({title:query.value}).then((res:any)=>{
                if(res.code == 200){
                    article.value = res.data.models;
                    query.value = '';
                }
            })
        }

        return{
            breadData,
            query,
            expandedKeys,
            selectedKeys,
            treeData,
            getTree,
            treeSelect,
            article,
            articleClick,
            search
        }
    }
})
